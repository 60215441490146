//-----------------------------
//nav
//-----------------------------
.nav {
	@at-root {
		& {
  		position: relative;
  		/*
      固定する場合
  		position: fixed;
  		top: 70px;
  		left: 0;
  		width: 300px;
  		*/
		}
		.nav__list {
  		padding: 30px 0;
		}
		.nav__list__item {
  		
		}
		.nav__list__link {
  		position: relative;
  		display: flex;
  		align-items: center;
  		height: 60px;
  		padding: 0 40px;
  		color: #000;
  		line-height: 1;
  		.icn {
    		display: block;
    		margin-right: 12px;
    		font-size: 1.8rem;
    		color: #939393;
  		}
  		.txt {
    		display: block;
  		}
  		&:hover {
    		color: $key_color;
    		.icn {
    		  color: $key_color;
    		}
  		}
  		&.-active {
    		background-color: #FAFDFA;
    		color: $key_color;
    		.icn {
    		  color: $key_color;
    		}
    		&::before {
      		content: "";
      		position: absolute;
      		top: 0;
      		bottom: 0;
      		left: 0;
      		display: block;
      		width: 5px;
      		height: 30px;
      		background-color: $key_color;
      		margin: auto 0;
    		}
  		}
		}
	}
}

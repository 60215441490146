//-----------------------------
//heading & pageTitle
//-----------------------------

.pageTitle {
  display: flex;
  align-items: center;
  height: 70px;
  margin: -30px -50px 30px;
  padding: 0 30px;
  box-shadow: 0 0 8px rgba(#000, 0.1);
  background-color: #FFFFFF;
}

.heading1 {
  font-size: 2rem;
  font-weight: 700;
  white-space: nowrap;
}

.heading2 {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-size: 1.8rem;
  font-weight: 700;
  white-space: nowrap;
  &::before {
    content: "";
    display: block;
    width: 20px;
    height: 5px;
    margin-right: 12px;
    border-radius: 5px;
    background-color: $key_color;
  }
}
@charset "utf-8";
$sp: 768;
$tb: 1600;
@mixin sp {
  @media screen and (max-width: ($sp + 0px)) {
    @content;
  }
}

@mixin tb {
  @media screen and (max-width: ($tb + 0px)) {
    @content;
  }
}

@mixin pc {
  @media screen and (min-width: ($sp + 1px)) {
    @content;
  }
}

@mixin landscape {
	@media (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
	@media (orientation: portrait) {
    @content;
  }
}

@function get_tb_vw($size, $viewport:$tb){
	$rate: calc(100 / $viewport);
	@return calc($rate * $size * 1vw);
}

@function get_vw($size, $viewport:375){
	$rate: calc(100 / $viewport);
	@return calc($rate * $size * 1vw);
}

@mixin vw($property, $px) {
	#{$property}: $px * 1px;
	@include sp {
		#{$property}: get_vw($px / 2);
	}
}

@mixin fontSize($font_size:10){
	font-size: get_vw($font_size);
}
@mixin fontSizeTb($font_size:10){
  font-size: $font_size / 10 + rem;
  @include tb {
	  font-size: get_tb_vw($font_size);
  }
}

@mixin rpTb($property, $px) {
  #{$property}: $px * 1px;
  @include tb {
	  #{$property}: get_tb_vw($px);
	}
}

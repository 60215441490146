//-----------------------------
//fonts
//-----------------------------
//icon
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined&display=swap');

//Noto Sans
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

//-----------------------------
//明朝体
//-----------------------------
%mincho{font-family: YuMincho, '游明朝', "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;}

//-----------------------------
//ゴシック体
//-----------------------------
%gothic{font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;}
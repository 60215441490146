//-----------------------------
//contents
//-----------------------------
.contents {
	@at-root {
		& {
  		display: flex;
  		justify-content: flex-start;
  		overflow: hidden;
		}
		.contents__nav {
  		position: relative;
  		width: 300px;
  		background-color: #fff;
  		box-shadow: 0 0 10px rgba(#000, 0.08);
  		z-index: 10;
		}
		.contents__main {
  		position: relative;
  		width: calc(100vw - 300px);
  		background-color: #FAFDFA;
  		z-index: 8;
		}
	}
}
//-----------------------------
//base
//-----------------------------
html {
	width: 100%;
	background-color: #fff;
	font-size: 62.5%;
	@extend %gothic;
	font-weight: 500;
	font-feature-settings: "palt" 1;
	letter-spacing: 0.075em;
  line-height: 1.5;
	color: $txt_color;
}

body {
	position: relative;
	width: 100%;
	margin: 0;
	font-size: 1.6rem;
	-webkit-text-size-adjust: 100%;
	word-wrap:break-word;
	-webkit-overflow-scrolling: touch;
}

a {
	color: $link_color;
	text-decoration: none;
}

a,
button {
  transition: 0.2s all;
  cursor: pointer;
}

input,
button,
select,
textarea {
	@extend %gothic;
}
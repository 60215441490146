//-----------------------------
//login
//-----------------------------

.login {
	@at-root {
		& {
  		min-height: 100vh;
  		padding: 100px 40px;
  		background-color: #FAFDFA;
    }
    .login__header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 64px;
    }
    .login__header__mark {
      display: block;
      width: 32px;
  		margin-right: 8px;
    }
    .login__header__txt {
  		display: block;
  		margin-right: 1em;
  		font-size: 3rem;
  		font-weight: 700;
    }
    .login__box {
      width: 600px;
      margin: 0 auto 35px;
      padding: 50px 70px;
      border-radius: 10px;
      box-shadow: 0 0 20px rgba(#62A262, 0.2);
      background-color: #fff;

      table {
        max-width: 100%;
        width: 100%;
        word-wrap: break-word;
        word-break: break-all;
      }

      th {
        display: block;
        margin-bottom: 10px;
        font-size: 1.8rem;
        line-height: 1.875;
        font-weight: bold;
      }
      
      td {
        display: block;
        margin-bottom: 40px;
        input {
          width: 100%;
        }
      }
    }
    .login__box__txt {
      font-size: 1.8rem;
      line-height: 1.875;
      + .login__box__list {
        margin-top: 30px;
      }
    }
    .login__box__list {
      margin-bottom: 30px;
    }
    .login__box__ttl {
      margin-bottom: 10px;
      font-size: 1.8rem;
      line-height: 1.875;
    }
    .login__box__item {
      margin-bottom: 40px;
      input {
        width: 100%;
      }
    }
    .login__box__btn {
      display: flex;
      justify-content: center;
      .button {
        min-width: 160px;
        height: 50px;
      }
    }
    .login__txt {
      font-size: 1.8rem;
      line-height: 1.875;
      text-align: center;
      a {
        text-decoration: underline;
      }
    }

  }
}
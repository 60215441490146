//-----------------------------
//tooltip
//-----------------------------

.tooltip {
	@at-root {
		& {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      .icn {
        margin-left: 5px;
        font-size: 2rem;
        color: #939393;
        cursor: pointer;
      }
      &:hover {
        .tooltip__inner {
          opacity: 1;
        }
      }
    }
    &.upsidedown {
      .tooltip__inner {
        bottom: 0;
        top: 100%;
      }
      .tooltip__balloon {
        &::before {
          top: -6px;
          transform: rotate(-135deg);
        }
      }
    }
    .tooltip__inner {
      position: absolute;
      right: -10vw;
      bottom: 100%;
      left: -10vw;
      display: block;
      text-align: center;
      opacity: 0;
      pointer-events: none;
      transition: 0.2s all;
    }
    .tooltip__balloon {
      position: relative;
      display: inline-block;
      max-width: 330px;
      margin-bottom: 6px;
      padding: 18px;
      border: 1px solid #96D796;
      box-shadow: 0 0 6px rgba(#96D796, 0.29);
      background-color: #fff;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.3;
      text-align: left;
      color: #000;
      white-space: normal;
      z-index: 200;
      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 12px;
        height: 12px;
        margin: 0 auto -6px;
        border: 1px solid transparent;
        border-right-color: #96D796;
        border-bottom-color: #96D796;
        background-color: #fff;
        transform: rotate(45deg);
      }
    }
  }
}
//-----------------------------
//table
//-----------------------------

.table {
  position: relative;
  margin: 20px 0;
  &.scroll-x {
    overflow-x: auto;
    overflow-y: clip;
    white-space: nowrap;
    width: 100%;
  }
  table {
    max-width: 100%;
    width: 100%;
    border: 1px solid #FFFFFF;
    word-wrap: break-word;
    word-break: break-all;
  }
  th {
    padding: 13px 20px;
    background-color: $key_color_light;
    white-space: nowrap;
    .icn {
      margin-right: -1rem;
    }
  }
  td {
    padding: 13px 20px;
    border: 1px solid #D6EFD6;
    font-weight: 500;
    .icn {
      font-size: 2.4rem;
      color: $key_color;
      vertical-align: middle;
    }
    .edit {
      display: flex;
      justify-content: center;
      .icn {
        color: #939393;
        transition: 0.2s all;
      }
      button {
        color: #939393;
        + button {
          margin-left: 25px;
        }
        &:hover {
          color: $key_color;
          .icn {
            color: $key_color;
          }
        }
      }
    }
    .list {
      display: flex;
      li {
        margin-right: 30px;
      }
      .icn {
        margin-right: 8px;
      }
    }
    .txtOverflow {
      position: relative;
      padding-right: 1em;
      &::after {
        content: "…";
        position: absolute;
        right: 0;
        bottom: 0;
      }
      span {
        display: block;
        overflow: hidden;
        font-size: 0;
        &:first-line {
          font-size: 1.6rem;
        }
      }
    }
  }
  thead {
    th {
      border-right: 1px solid #FFFFFF;
      border-bottom: 1px solid #FFFFFF;
      border-left: 1px solid #FFFFFF;
      font-weight: 700;
      &:first-child {
        border-left: 1px solid $key_color_light;
      }
      &:last-child {
        border-right: 1px solid $key_color_light;
      }
    }
    td {
      
    }
  }
  tbody {
    th {
      border-top: 1px solid #FFFFFF;
      border-right: 1px solid $key_color_light;
      border-bottom: 1px solid #FFFFFF;
      border-left: 1px solid $key_color_light;
      font-weight: 500;
    }
    td {
      
    }
    tr {
      &:first-child {
        th {
          border-top: 1px solid $key_color_light;
        }
      }
      &:last-child {
        th {
          border-bottom: 1px solid $key_color_light;
        }
      }
    }

    .table-success {
      background-color: #eff8ef;
      color: #244424;

      a {
        color: #244424;
        text-decoration: underline;
      }

      .edit {
        .icn {
          color: #244424;
        }
      }

    }

    .table-dark {
      background-color: #c4c4c4;
      color: #454d55;

      a {
        color: #454d55;
        text-decoration: underline;
      }

      a:hover {
        color: #454d55;
      }
    }

    background-color: #fff;
  }
}
//-----------------------------
//modal
//-----------------------------

.modal {
	@at-root {
		& {
  		position: fixed;
  		top: 0;
  		right: 0;
  		bottom: 0;
  		left: 0;
  		padding: 30px 30px 60px;
  		background-color: rgba(#000 , 0.5);
  		z-index: 300;
  		pointer-events: none;
      transition: 0.4s all;
  		opacity: 1;
   		pointer-events: auto;
    }
    .modal__inner {
      max-width: 900px;
      height: 100%;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: 0 0 30px rgba(#000, 0.2);
      background-color: #fff;
      overflow: hidden;
    }
    .modal__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 82px;
      padding: 0 40px;
      border-bottom: 1px solid #96D796;
    }
    .modal__close {
      cursor: pointer;
      .icn {
        font-size: 2.8rem;
        color: #000;
      }
    }
    .modal__body {
      height: calc(100% - 82px);
      padding: 20px 40px 50px;
      overflow: auto;
    }

    .modal-dialog,
    .modal-content {
      height: 100%;
    }
  }
}
//-----------------------------
//utility
//-----------------------------
//width

.u-w20p {
  width: 20%;
}
.u-w25p {
  width: 25%;
}
.u-w30p {
  width: 30%;
}
.u-w35p {
  width: 35%;
}
.u-w40p {
  width: 40%;
}
.u-w45p {
  width: 45%;
}
.u-w50p {
  width: 50%;
}
.u-w55p {
  width: 55%;
}
.u-w60p {
  width: 60%;
}
.u-w65p {
  width: 65%;
}
.u-w70p {
  width: 70%;
}
.u-w75p {
  width: 75%;
}
.u-w10 {
  width: 10px;
}
.u-w300 {
  width: 300px;
}

//text-align
.u-taC {
  text-align: center;
}
.u-taR {
  text-align: right;
}

//改行禁止
.u-nowrap {
  white-space: nowrap;
}

//上付き文字
.u-sup {
  vertical-align: super;
  font-size: 0.75em;
}

//下付き文字
.u-sub {
  vertical-align: sub;
  font-size: 0.75em;
}

//flex
.u-flex {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row;
  &.-aiCenter {
    align-items: center;
  }
  &.-aiStert {
    align-items: flex-start;
  }
  &.-aiEnd {
    align-items: flex-end;
  }
  &.-jcCenter {
    justify-content: center;
  }
  &.-jcAround {
    justify-content: space-around;
  }
  &.-jcBetween {
    justify-content: space-between;
  }
  &.-wrap {
    flex-wrap: wrap;
  }
  &.-fdReverse {
    flex-direction: row-reverse;
  }
  &.-fdColumn {
    flex-direction: column;
  }
}
//-----------------------------
//pager
//-----------------------------

.pager {
	@at-root {
		& {
  		display: flex;
  		justify-content: center;
  		margin-top: 100px;
    }
    .pager__arrow {
  		display: flex;
  		align-items: center;
  		justify-content: center;
      width: 25px;
      height: 25px;
      &.-prev {
        margin-right: 30px;
      }
      &.-next {
        margin-left: 30px;
      }
      .icn {
        display: flex;
        font-size: 2rem;
        color: #94D894;
        transition: 0.2s all;
      }
      &:hover {
        .icn {
          color: #108614;
        }
      }
    }
    .pager__list {
  		display: flex;
  		justify-content: center;
    }
    .pager__list__item {
      margin: 0 10px;
    }
    .pager__list__link {
  		display: flex;
  		align-items: center;
  		justify-content: center;
      width: 25px;
      height: 25px;
      padding-bottom: 2px;
      &.-active {
        border: 1px solid $key_color;
        border-radius: 2px;
      }
      &:hover {
        color: #108614;
      }
    }
  }
}
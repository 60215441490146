//-----------------------------
//line
//-----------------------------

#embed_line {
  padding: 0 get_vw(20) get_vw(40) !important;
  background-color: #FAFDFA;

  h2 {
    display: flex;
    align-items: center;
    height: get_vw(60);
    margin: 0 get_vw(-20) get_vw(20);
    padding: 0 get_vw(20);
    box-shadow: 0 0 8px rgba(#000, 0.1);
    background-color: #FFFFFF;
    @include fontSize(18);
    font-weight: 700;
    white-space: nowrap;
    color: #000;
  }

  .alert {
    margin-bottom: get_vw(40);
    padding: get_vw(20);
    border: 1px solid #A3D59A;
    border-radius: get_vw(5);
    background-color: #FFFFFF;
    @include fontSize(14);
    line-height: 1.875;
  }

  .form-group {
    margin-bottom: get_vw(20);

    >label {
      margin-bottom: get_vw(10);
      @include fontSize(14);
      font-weight: 600;
      color: #000;

      .text-danger {
        display: inline-block;
        margin-left: 1em;
        @include fontSize(14);
        font-weight: 500;
        color: #F53232;
      }
    }

    >div {
      width: 100%;
    }

    .form-group {
      margin-bottom: 0;
    }
  }

  .form-table {
    display: block;

    tbody {
      display: block;
    }
    tr {
      margin-bottom: get_vw(20);
      display: block;

      th {
        margin-bottom: get_vw(10);
        @include fontSize(14);
        font-weight: 600;
        color: #000;
        display: block;

        &.required {
          &::after {
            content: "【必須】";
            display: inline-block;
            margin-left: 1em;
            color: #F53232;
            @include fontSize(14);
          }
        }
      }

      >div {
        width: 100%;
      }

      tr {
        margin-bottom: 0;
      }

      td {
        display: block;
      }
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: get_vw(50);
    padding: 0 get_vw(10);
    background-color: #00b900;
    border-color: #00b900;
    border-radius: get_vw(5);
    @extend %gothic;
    @include fontSize(16);
    font-weight: 500;
    color: #fff;
    width: 100%;

    &:hover {
      background-color: #108614;
    }
    &.-inactive {
      pointer-events: none;
      background-color: #DBDBDB;
      border: 0;
    }
  }

  /*radio*/
  .form-inline {
    display: flex;
  }

  .form-check {
    width: auto;
    margin-right: get_vw(25);
    margin-bottom: get_vw(15);
    padding: 0;
    @include fontSize(14);
    font-weight: 500;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="url"],
  input[type="number"],
  input[type="password"] {
    width: 100%;
    height: get_vw(40);
    padding: 0 get_vw(10);
    border: 1px solid #94D894;
    border-radius: get_vw(5);
    @extend %gothic;
    font-size: 1.6rem;
    font-weight: 500;
    transition: 0.2s all;

    &:focus {
      box-shadow: 0 0 10px rgba(#D7EED7, 1);
      background-color: #FAFDFA;
    }

    &.error,
    &:invalid {
      border-color: $effect_color;
      box-shadow: 0 0 10px rgba(#FDC3C3, 1);
      background-color: #FFF7F7;
      color: $effect_color;
    }

    &::placeholder {
      color: #939393;
    }
  }

  input[type="radio"] {
    position: static;
    width: get_vw(20);
    height: get_vw(20);
    margin: 0;
    margin-right: get_vw(6);
    border: 1px solid $key_color;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 0 0 0 6px #FFFFFF inset;

    &:checked {
      background: linear-gradient(#1BB71F 6px, #96D796 calc(100% - 6px));
    }
  }

  input[type="checkbox"] {
    position: static;
    width: get_vw(20);
    height: get_vw(20);
    margin: 0;
    margin-right: get_vw(6);
    border: 1px solid $key_color;
    border-radius: get_vw(2);
    background-color: #FFFFFF;

    &:checked {
      background: $key_color url(../img/check.png) no-repeat center center;
      background-size: contain;
    }
  }

  .error {
    color: $effect_color;

    input[type="radio"],
    input[type="checkbox"] {
      border-color: $effect_color;
      box-shadow: 0 0 10px rgba(#FDC3C3, 1);
      background-color: #FFF7F7;
    }
  }

  textarea {
    width: 100%;
    height: 160px;
    padding: 10px 20px;
    border: 1px solid #94D894;
    border-radius: 4px;
    font-size: 1.6rem;
    font-weight: 500;

    &.error,
    &:invalid {
      border-color: $effect_color;
      box-shadow: 0 0 10px rgba(#FDC3C3, 1);
      background-color: #FFF7F7;
      color: $effect_color;
    }
  }

  /*select*/
  .form-select {
    width: 100%;
    height: get_vw(40);
    padding: 0 get_vw(10);
    border: 1px solid #94D894;
    border-radius: get_vw(5);
    background-color: #fff;
    @extend %gothic;
    font-size: 1.6rem;
    font-weight: 500;
    transition: 0.2s all;
  }

  .form-ritch-select {
    border: 1px solid #94D894;
    background-color: #fff;
    border-radius: get_vw(5);
    @extend %gothic;
  }

  .material-icons {
    position: relative;
    width: auto;

    &::before {
      content: attr(data-icon);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      margin-right: 15px;
      @include fontSize(24);
      color: $key_color;
      pointer-events: none;
    }
  }

  .label {
    display: inline-flex;
    align-items: center;
    width: 30%;
    cursor: pointer;

    &.select {
      position: relative;
      width: 32%;

      &.error {
        &::before {
          color: $effect_color;
        }

        select {
          border-color: $effect_color;
          box-shadow: 0 0 10px rgba(#FDC3C3, 1);
          background-color: #FFF7F7;
          color: $effect_color;
        }
      }
    }
  }

  .d-flex {
    display: flex;
    .ml-1 {
      margin-left: 1em;
    }
  }

  .invisible {
    display: none;
  }
}
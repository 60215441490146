//-----------------------------
//header
//-----------------------------
.header {
	@at-root {
		& {
  		position: relative;
  		z-index: 100;
  		height: 70px;
		}
		.header__inner {
  		/*
      固定する場合
  		position: fixed;
  		top: 0;
  		right: 0;
  		left: 0;
  		z-index: 100;
  		*/
  		display: flex;
  		background-color: #fff;
		}
		.header__logo {
  		display: flex;
  		align-items: center;
  		justify-content: center;
  		min-width: 300px;
  		width: 300px;
  		height: 70px;
  		border-bottom: 1px solid #D9E5D9;
		}
		.header__logo__link {
  		display: flex;
  		color: #000;
		}
		.header__logo__mark {
  		display: block;
  		width: 20px;
  		margin-right: 10px;
		}
		.header__logo__txt {
  		display: block;
  		margin-right: 1em;
  		font-size: 1.8rem;
  		font-weight: 700;
		}
		.header__nav {
  		display: flex;
  		align-items: center;
  		justify-content: space-between;
  		min-width: 800px;
  		width: calc(100vw - 300px);
  		height: 70px;
  		padding: 0 50px 0 30px;
  		background-color: $key_color;
		}
		.header__nav__ttl {
  		  font-size: 1.8rem;
		  color: #fff;
		}
		.header__nav__inner {
  		display: flex;
  		align-items: center;
  		justify-content: flex-end;
		}
		.header__nav__icn {
  		position: relative;
  		display: block;
  		color: #fff;
  		margin-left: 32px;
  		font-size: 2.4rem;
  		&:hover {
    		color: #94D894;
  		}
  		&.-alert {
    		&::before {
      		content: "";
      		position: absolute;
      		top: 0.1em;
      		right: 0.1em;
      		display: block;
      		width: 10px;
      		height: 10px;
      		border-radius: 50%;
          background-color: $effect_color;
    		}
  		}
		}
		.header__user {
  		position: relative;
  		display: flex;
  		align-items: center;
  		margin-left: 40px;
  		&:hover {
    		.header__user__dropdown {
      		opacity: 1;
      		pointer-events: auto;
    		}
  		.header__user__name {
    		color: #94D894;
  		}
  		}
		}
		.header__user__img {
  		width: 40px;
  		height: 40px;
  		margin-right: 10px;
  		border-radius: 50%;
  		overflow: hidden;
		}
		.header__user__name {
  		color: #fff;
		}
		.header__user__dropdown {
  		position: absolute;
  		top: 85%;
  		right: 0;
      box-shadow: 0 0 6px rgba(#96D796, 0.29);
  		opacity: 0;
  		pointer-events: none;
      transition: 0.2s all;
		}
		.header__user__dropdown__list {
  		border: 1px solid #96D796;
  		border-radius: 4px;
  		overflow: hidden;
		}
		.header__user__dropdown__item {
  		+ .header__user__dropdown__item {
    		border-top: 1px solid #96D796;
  		}
		}
		.header__user__dropdown__link {
  		display: flex;
  		align-items: center;
  		width: 100%;
  		height: 50px;
  		padding: 0 20px;
  		background-color: #FFFFFF;
  		white-space: nowrap;
  		.icn {
    		margin-right: 10px;
    		font-size: 2rem;
    		color: #94D894;
  		}
  		.txt {
    		font-size: 1.6rem;
    		font-weight: 500;
    		color: #939393;
  		}
  		&:hover {
    		background-color: #FAFDFA;
    		.icn,
    		.txt {
    		  color: $key_color;
    		}
  		}
		}
	}
}

//-----------------------------
//status
//-----------------------------

.status {
	@at-root {
		& {
  		position: fixed;
  		top: 0;
  		right: 0;
  		left: 0;
  		display: flex;
  		align-items: center;
  		justify-content: center;
  		height: 70px;
  		background-color: $key_color;
  		pointer-events: none;
  		z-index: 200;
  		opacity: 0;
  		transform: translateY(-70px);
      transition: 1s all;
  		&.-error {
    		background-color: $effect_color;
  		}
  		&.-active {
    		opacity: 1;
    		transform: translateY(0);
  		}
    }
    .status__txt {
      text-align: center;
      color: #fff;
    }
  }
}
//-----------------------------
//main
//-----------------------------
.main {
	@at-root {
		& {
  		min-width: 800px;
  		min-height: calc(100vh - 70px - 60px); // 100vh - ヘッダーの高さ - フッターの高さ
  		padding: 30px 50px 50px;
		}
		.main__section {
  		margin-bottom: 50px;
		}
		.main__hr {
  		margin: 70px 0;
  		width: 100%;
  		height: 1px;
  		background: #96D796;
  		outline: 0;
  		padding: 0;
  		border: 0;
		}
	}
}


//-----------------------------
//footer
//-----------------------------
.footer {
	@at-root {
		& {
  		display: flex;
  		align-items: center;
  		height: 60px;
  		padding: 0 50px;
  		border-top: 1px solid #D7EED7;
		}
		.footer__copyright {
  		font-size: 1.2rem;
  		color: #939393;
		}
	}
}
//-----------------------------
// benefit
//-----------------------------

.benefit {
  @at-root {
    .form-line {
        justify-content: center;
        align-items: center;
        display: flex;
        margin-bottom: 20px;

        .select-box {
            width: 300px;
            padding-left:1em;
            padding-right:1em;
        }
    }
  }
}
//-----------------------------
// chat
//-----------------------------

.chat {
  @at-root {
    & {
        background-color: #fff;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        margin-left: 16.66667%;
    }

    .friend-comment {
        justify-content: flex-start!important;
        display: flex!important;
        .balloon {
            white-space: pre-line;
            border-radius: 0 20px 20px 20px;
            color: $txt_color!important;
            background-color: $second_color!important;
            padding: 1rem!important;

        }
    }

    .account-comment {
        justify-content: flex-end!important;
        display: flex!important;
        margin-bottom: 1rem!important;

        .balloon {
            white-space: pre-line;
            border-radius: 20px 0 20px 20px;
            color: #fff!important;
            background-color: $key_color!important;
            padding: 1rem!important;
        }
    }

    .time {
        display: block;
        font-size: small;
        color: #6c757d!important;;
    }
  }
}
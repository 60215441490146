//-----------------------------
//info
//-----------------------------

.info {
	@at-root {
		& {
  		margin-bottom: 20px;
  		padding: 20px;
  		border: 1px solid #A3D59A;
  		border-radius: 4px;
  		background-color: #FFFFFF;
    }
    .info__txt {
      font-size: 1.6rem;
      line-height: 1.875;
    }
  }
}
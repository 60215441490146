//-----------------------------
//form
//-----------------------------

.form {
  @at-root {
    & {}

    .form__table {
      width: 100%;

      th {
        width: 340px;
        padding: 20px 50px 20px 0;
        font-size: 1.8rem;
        font-weight: 500;
        vertical-align: top;

        &.required {
          &::after {
            content: "*必須";
            display: inline-block;
            margin-left: 1em;
            color: $effect_color;
          }
        }
      }

      td {
        padding: 10px 0;
        vertical-align: top;
      }
    }

    .form__list {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;
    }

    .form__list__item {
      margin-right: 36px;
      font-size: 1.8rem;
      font-weight: 500;
    }

    .form__buttons {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      .button {
        min-width: 160px;
        height: 50px;
        margin: 0 20px;

        .txt {
          font-size: 1.8rem;
        }
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="url"],
    input[type="number"],
    input[type="password"] {
      width: 400px;
      height: 50px;
      padding: 0 20px;
      border: 1px solid #94D894;
      border-radius: 4px;
      font-size: 1.8rem;
      font-weight: 500;
      transition: 0.2s all;

      &:focus {
        box-shadow: 0 0 10px rgba(#D7EED7, 1);
        background-color: #FAFDFA;
      }

      &.error,
      &:invalid {
        border-color: $effect_color;
        box-shadow: 0 0 10px rgba(#FDC3C3, 1);
        background-color: #FFF7F7;
        color: $effect_color;
      }

      &::placeholder {
        color: #939393;
      }
    }

    input[type="radio"] {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border: 1px solid $key_color;
      border-radius: 50%;
      background-color: #FFFFFF;
      box-shadow: 0 0 0 6px #FFFFFF inset;

      &:checked {
        background: linear-gradient(#1BB71F 6px, #96D796 calc(100% - 6px));
      }
    }

    input[type="checkbox"] {
      width: 26px;
      height: 26px;
      margin-right: 10px;
      border: 1px solid $key_color;
      border-radius: 2px;
      background-color: #FFFFFF;

      &:checked {
        background: $key_color url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAMAAADypuvZAAAAbFBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8EZPb1AAAAI3RSTlMA5B4T4CHoaI/YhxobF5Zr1OPng9sWIsEUbNffIHyAEYUcEHf6SSkAAACsSURBVEjH7dXbDsIgDAbgeqTTOTfP58P6/u84omNBoqFtYrIL/kvKlybQAEBKSq+T7y9iMx9TlcsN0XkhN0QbiXq+DdFJ3MfGsM1QYwb/MNd7uLKrnFn+MAWVtbRPYWvlSGbwVc08NYmadVvPDp2ZRs/t5na4XgwDMPtULBMopvHVlm0AHp1axe70Wy/RvKHCBIo916gwnjKSZwQVplVG+jSiwlh1TB9RSj/TACV9J0IJvBbuAAAAAElFTkSuQmCC) no-repeat center center;
        background-size: contain;
      }
    }

    .error {
      color: $effect_color;

      input[type="radio"],
      input[type="checkbox"] {
        border-color: $effect_color;
        box-shadow: 0 0 10px rgba(#FDC3C3, 1);
        background-color: #FFF7F7;
      }
    }

    textarea {
      width: 100%;
      height: 160px;
      padding: 10px 20px;
      border: 1px solid #94D894;
      border-radius: 4px;
      font-size: 1.8rem;
      font-weight: 500;

      &.error,
      &:invalid {
        border-color: $effect_color;
        box-shadow: 0 0 10px rgba(#FDC3C3, 1);
        background-color: #FFF7F7;
        color: $effect_color;
      }
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.select {
        position: relative;

        &::before {
          content: attr(data-icon);
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          margin-right: 15px;
          font-size: 2.8rem;
          color: $key_color;
          pointer-events: none;
        }

        select {
          width: 400px;
          height: 50px;
          padding: 0 20px;
          border: 1px solid #94D894;
          border-radius: 4px;
          font-size: 1.8rem;
          font-weight: 500;
          transition: 0.2s all;
        }

        &.error {
          &::before {
            color: $effect_color;
          }

          select {
            border-color: $effect_color;
            box-shadow: 0 0 10px rgba(#FDC3C3, 1);
            background-color: #FFF7F7;
            color: $effect_color;
          }
        }
      }
    }
  }
  /*select*/
  .form-select {
    width: 100%;
    height: get_vw(10);
    padding: 0 get_vw(10);
    border: 1px solid #94D894;
    border-radius: get_vw(2);
    background-color: #fff;
    @extend %gothic;
    font-size: 1.6rem;
    font-weight: 500;
    transition: 0.2s all;
  }

  .form-ritch-select {
    border: 1px solid #94D894;
    background-color: #fff;
    border-radius: get_vw(5);
    @extend %gothic;
  }
  .d-flex {
    display: flex;
    .ml-1 {
      margin-left: 1em;
    }
  }
}

/*
          
  <div id="modal1" class="modal">
    <div class="modal__inner">
      <div class="modal__header">
        <h3 class="heading2">LINEアカウント表示設定</h3>
      <!--/.modal__header--></div>
      <div class="modal__body">
        <div class="form">
          <table class="form__table">
            <tbody>
              <tr>
                <th></th>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div class="form__buttons">
            <button class="button -secondary">
              <span class="txt">キャンセル</span>
            </button>
            <button class="button -primary">
              <span class="txt">保存</span>
            </button>
          <!--/.form__buttons--></div>
        <!--/.form--></div>
      <!--/.modal__body--></div>
    <!--/.modal__inner--></div>
  <!--/.modal--></div>
  
*/
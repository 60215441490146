//-----------------------------
//button
//-----------------------------

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .button {
    margin-left: 15px;
  }
  &.-line {
    //ボタン幅均等
    .button {
      min-width: 160px;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  height: 40px;
  padding: 0 15px;
  border-radius: 4px;
  .icn {
    font-size: 2.4rem;
  }
  .txt {
    margin: 0 8px;
    font-size: 1.6rem;
    font-weight: 500;
  }
  &.-primary {
    background-color: $key_color;
    .icn {
      color: #fff;
    }
    .txt {
      color: #fff;
    }
    &:hover {
      background-color: #108614;
    }
  }
  &.-secondary {
    background-color: $second_color;
    .icn {
      color: $key_color;
    }
    .txt {
      color: $key_color;
    }
    &:hover {
      background-color: #939393;
      .icn {
        color: #fff;
      }
      .txt {
        color: #fff;
      }
    }
  }
  &.-ghost {
    background-color: #fff;
    border: 2px solid $key_color;
    .icn {
      color: $key_color;
    }
    .txt {
      color: $key_color;
    }
    &:hover {
      background-color: $key_color;
      .icn {
        color: #fff;
      }
      .txt {
        color: #fff;
      }
    }
  }
  &.-inactive {
    pointer-events: none;
    background-color: #DBDBDB;
    border: 0;
    .icn {
      color: #fff;
    }
    .txt {
      color: #fff;
    }
  }

  &.-inline {
    min-width: 50px;
    display: inline;
    margin-left: 20px;
  }
}